<template>
  <div
    class="wrapper d-flex align-center"
    @click.stop
  >
    <v-menu
      v-if="hasComment"
      :attach="attachEl"
      close-on-content-click
      offset-y
      content-class="background-translucid"
      :z-index="displayZIndex"
      min-width="max-content"
      ref="menu"
      transition="slide-y-transition"
      origin="right top"
      :nudge-bottom="displayToTop ? 0 : 17"
      :nudge-top="displayToTop ? 12 : 0"
      :top="displayToTop"
      :left="displayToLeft"
      v-model="menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <span
          class="d-flex"
          v-on="on"
          v-bind="attrs"
          @mouseover="openMenu"
          @mouseleave="closeMenu"
        >
          <span class="text-no-wrap">{{commentPreview}}</span>
          <v-icon
            small
            v-if="!commentPreviewIsFull"
          >{{$icon('i.DotsHorizontal')}}</v-icon>
        </span>
      </template>
      <v-card
        class="background-plain ma-0 d-flex flex-column"
        ref="content"
        v-if="!commentPreviewIsFull"
        @mouseover="keepOpen"
        @mouseleave="closeMenu"
      >
        <resize-observer @resize="computePosition()">
          <v-card-text class="content-menu background-plain">
            <comment-value
              :document-id="documentId"
              :document-type="documentType"
              :comment-column-name="commentColumnName"
            />
          </v-card-text>
        </resize-observer>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Column from '@/pages/search/controllers/column'
import DisplayableInContainer from '@/pages/search/mixins/displayable-in-container'

export default {
  inheritAttrs: false,
  mixins: [DisplayableInContainer],
  components: {
    CommentValue: () => import('./comment-value'),
    ResizeObserver: () => import('@/components/resize-observer')
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    hasComment () {
      return !!this.value?.documentId
    },
    documentId () {
      return this.value?.documentId?.id
    },
    documentType () {
      return this.column?.options?.documentType
    },
    commentColumnName () {
      return this.value?.commentColumnName
    },
    commentPreview () {
      return this.value?.commentPreview
    },
    commentPreviewIsFull () {
      return this.value?.commentPreviewIsFull
    }
  },
  methods: {
    openMenu () {
      this.menu = true
    },
    closeMenu () {
      this.menu = false
    },
    keepOpen () {
      this.menu = true
    }
  },
  props: {
    value: Object,
    column: Column
  }
}
</script>
<style lang="stylus" scoped>
.wrapper
  position relative

.content-menu
  overflow-y true
  max-width 600px
  max-height 600px
</style>
